<template>
  <div>
    <div v-if="!loaded">
      Loading...
    </div>
    <div v-else>
      <user-detail />
      <b-form-group>
        <b-form-radio-group
          id="btn-radios-1"
          v-model="typeFilter"
          class="my-50"
          button-variant="outline-primary"
          :options="optionsRadio"
          buttons
          name="radios-btn-default"
          :size="isMobile ? 'sm' : 'md'"
        />
        <b-button
          class="mx-1 my-50"
          variant="outline-info"
          :disabled="availableToPayout < 200"
          @click.prevent="onPay(monthsAvailableToPayout)"
        >
          Set all as paid - {{ formatCurrency(availableToPayout, 'USD') }}
        </b-button>
      </b-form-group>
      <b-table
        :items="payouts"
        :fields="fields"
        striped
        responsive
      >
        <template #cell(totalProfit)="data">
          {{ formatCurrency(data.item.totalProfit, 'USD') }}
        </template>

        <template #cell(totalPayout)="data">
          {{ formatCurrency(data.item.totalPayout, 'USD') }}
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="data.item.askForPayout && !data.item.isPaid"
            variant="warning"
          >
            Asking for payout
          </b-badge>
          <b-badge
            v-if="data.item.isPaid"
            variant="success"
          >
            Paid
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <action-list
            v-if="typeFilter === null"
            :items="actions"
            @pay="onPay([data.item.month])"
          />
        </template>
      </b-table>

      <app-collapse>
        <app-collapse-item
          class="my-1"
          title="All orders summary"
        >
          <orders-summary-table :orders="orders" />
        </app-collapse-item>

        <app-collapse-item
          class="my-1"
          title="Orders"
        >
          <orders-data-table :default-filter="orderDefaultFilter" />
        </app-collapse-item>
      </app-collapse>
      <b-card>
        <b-card-title>
          Registered users
        </b-card-title>
        <registered-users-table :aff-id="partnerId" />
      </b-card>

      <email-modal ref="email-modal" />
    </div>
  </div>
</template>

<script>
import {
  BTable, BBadge, BFormRadioGroup, BFormGroup, BCard, BCardTitle, BButton,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { AFF_ACCOUNTS_SELECTOR } from '@/enums/accounts'
import UserDetail from './components/UserDetail.vue'
import EmailModal from './components/EmailModal.vue'
import { formatCurrency } from '@/plugins/formaters'
import ActionList from '@/components/ui/ActionList.vue'
import AffiliateAPI from '@/api/affiliate'
import OrdersSummaryTable from '@/views/affiliate/detail/components/OrdersSummaryTable.vue'
import RegisteredUsersTable from '@/views/affiliate/detail/components/RegisteredUsersTable.vue'
import OrdersDataTable from '@/components/page/orders/OrdersDataTable.vue'

const actions = [
  {
    text: 'Pay',
    icon: 'DollarSignIcon',
    emit: 'pay',
  },
]

export default {
  components: {
    OrdersDataTable,
    RegisteredUsersTable,
    OrdersSummaryTable,
    ActionList,
    UserDetail,
    BTable,
    EmailModal,
    BBadge,
    BFormGroup,
    BFormRadioGroup,
    BCard,
    BCardTitle,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      loaded: false,
      actions,
      optionsRadio: AFF_ACCOUNTS_SELECTOR,
      fields: [
        {
          key: 'month',
          sortable: true,
        },
        {
          key: 'totalProducts',
          sortable: true,
        },
        {
          key: 'totalPayout',
          sortable: true,
        },
        {
          key: 'totalProfit',
          sortable: true,
        },
        {
          key: 'status',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      typeFilter: null,
    }
  },
  computed: {
    ...get('app', ['isMobile']),
    ...get('partnersList@row', {
      affiliate: 'affiliate', payouts: 'payouts', orders: 'orders',
    }),
    orderDefaultFilter() {
      return {
        state: [], search: '', viewZeroCostOrders: false, affId: this.partnerId, processor: [],
      }
    },
    partnerId() {
      return this.$route.params.id
    },
    availableToPayout() {
      let sum = 0

      this.payouts.forEach(item => {
        const date = item.month.split('-')
        const now = new Date()
        // eslint-disable-next-line eqeqeq
        if (!item.isPaid && item.askForPayout && !(now.getFullYear() == date[0] && (now.getUTCMonth() + 1) == date[1])) {
          sum += Number(item.totalPayout)
        }
      })

      return sum
    },
    monthsAvailableToPayout() {
      const months = []
      this.payouts.forEach(item => {
        const date = item.month.split('-')
        const now = new Date()
        // eslint-disable-next-line eqeqeq
        if (!item.isPaid && item.askForPayout && !(now.getFullYear() == date[0] && (now.getUTCMonth() + 1) == date[1])) months.push(item.month)
      })
      return months
    },
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      dispatch('partnersList/get', this.partnerId).then(() => {
        this.loaded = true
      }).catch(err => {
        if (err.status === 404) {
          this.$router.push({ name: 'error-404' })
        }
      })
    },
    formatCurrency,
    onPay(months) {
      months.forEach(month => {
        const now = new Date()
        const date = month.split('-')
        // eslint-disable-next-line eqeqeq
        if (now.getFullYear() == date[0] && (now.getUTCMonth() + 1) == date[1]) {
          Swal.fire({
            title: 'Unable to proceed',
            text: 'Unable to payout current month',
            icon: 'error',
          })
        }
      })
      AffiliateAPI.patchAffiliateAsPaid({ months, partnerId: this.partnerId }).then(() => {
        Swal.fire({
          title: 'Success',
          text: 'Month was set as paid',
          icon: 'success',
        })
        this.getData()
      })
    },
  },
}
</script>
