<template>
  <b-card>
    <b-row>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <b-avatar
            variant="light-primary"
            size="104px"
            rounded
          >
            <UserIcon />
          </b-avatar>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                <b-link :to="{ name: 'user-detail', params: { id: affiliate.user.id } }">
                  {{ affiliate.user.full_name }}
                </b-link>
              </h4>
              <span class="card-text">{{ affiliate.user.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-b-modal.modal-email
                variant="primary"
              >
                Send email
              </b-button>
            </div>
          </div>
          <div class="d-flex flex-column ml-1">
            <tags-list :tags="affiliate.user.tags" />
          </div>
        </div>

        <div class="d-flex align-items-center mt-2 flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(statistics.totalProfit, 'USD') }}
              </h5>
              <small>Total profit</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(statistics.totalPayout, 'USD') }}
              </h5>
              <small>Total payout</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DatabaseIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ statistics.totalSold }}
              </h5>
              <small>Total orders</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(totalSpend, 'usd') }}
              </h5>
              <small>Total spend</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="CreditCardIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ ordersCount }}
              </h5>
              <small>Total number of purchase</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="UserIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ users_count }}
              </h5>
              <small>Number of registered</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="ChartIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(totalSpend/users_count, 'usd') }}
              </h5>
              <small>Spend per user</small>
            </div>
          </div>

        </div>
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ affiliate.user.address }}, {{ affiliate.user.zipcode }} {{ affiliate.user.city }} {{ affiliate.user.state }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td>
              {{ affiliate.user.country.name }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Affiliate key</span>
            </th>
            <td>
              {{ affiliate.aff_key }} ({{ affiliate.aff_custom_key }})
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="UserPlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered</span>
            </th>
            <td>
              {{ formatDateTime(affiliate.user.createdAt) }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="Edit2Icon"
                class="mr-75"
              />
              <span class="font-weight-bold">Signed up as affiliate</span>
            </th>
            <td>
              {{ affiliate.sign_up_at ? formatDateTime(affiliate.sign_up_at) : 'not signed yet' }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <hr>
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PercentIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Profit</span>
            </th>
            <td
              class="cursor-pointer"
              @click="onProfitClick"
            >
              {{ affiliate.profit }}%
              <feather-icon
                icon="EditIcon"
                class="ml-75"
              />
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink,
} from 'bootstrap-vue'
import { UserIcon } from 'vue-feather-icons'
import { get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import TagsList from '@/components/page/TagsList.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import store from '@/store'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, UserIcon, BLink, TagsList,
  },
  computed: {
    ...get('partnersList@row', {
      affiliate: 'affiliate', payouts: 'payouts', ordersCount: 'orders_count', totalSpend: 'orders_sum', users_count: 'users_count',
    }),
    statistics() {
      return {
        totalProfit: this.payouts.reduce((a, b) => a + Number(b.totalProfit), 0),
        totalPayout: this.payouts.reduce((a, b) => a + Number(b.totalPayout), 0),
        totalSold: this.payouts.reduce((a, b) => a + Number(b.totalProducts), 0),
      }
    },
  },
  methods: {
    formatDateTime,
    formatCurrency,
    async onProfitClick() {
      const { value: range } = await Swal.fire({
        title: 'Enter new profit',
        input: 'range',
        inputLabel: 'Profit',
        inputAttributes: {
          min: 1,
          max: 100,
          step: 1,
        },
        inputValue: this.affiliate.profit,
      })

      if (range !== this.affiliate.profit) {
        store.dispatch('partnersList/update', { id: this.affiliate.id, data: { profit: Number(range) } }).then(() => {
          this.affiliate.profit = range
        })
      }
    },
  },
}
</script>
