<template>
  <b-modal
    id="modal-email"
    cancel-variant="outline-secondary"
    ok-title="Send"
    cancel-title="Close"
    centered
    size="lg"
    title="Send email"
    @ok="handleOk"
  >
    <b-form ref="emailForm">
      <b-form-group>
        <label>Send to:</label>
        <b-form-input
          ref="email"
          :value="affiliate.user.email"
          type="email"
          disabled
          placeholder="Email Address"
        />
      </b-form-group>
      <b-form-group>
        <label>Subject</label>
        <b-form-input
          ref="subject"
          v-model="mail.subject"
          type="text"
          placeholder="Subject"
        />
      </b-form-group>
      <b-form-group>
        <label>Text</label>
        <b-form-textarea
          ref="textarea"
          v-model="mail.content"
          placeholder="Enter something..."
          rows="10"
          max-rows="30"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormInput, BFormTextarea, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get } from 'vuex-pathify'
import AffAPI from '@/api/affiliate'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      mail: {
        subject: '',
        content: '',
      },
    }
  },
  computed: {
    ...get('affiliate@partner.affiliate.user', ['email']),
    ...get('partnersList@row', { affiliate: 'affiliate' }),
  },
  methods: {
    handleOk() {
      AffAPI.sendCustomAffMail({ ...this.mail, email: this.affiliate.user.email })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email',
              icon: 'SendIcon',
              text: 'Email was sent successfully',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          Swal.fire({
            title: 'Sending email failed!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>
