<template>
  <entity-form
    :id="order ? order.id : null"
    module-name="ordersList"
    :model="form"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <entity-select
      v-model="form.user"
      :filter="userFilter"
    />

    <hr>
    <b-form-group
      label="Product"
    >
      <product-select
        v-model="form.product"
      />
    </b-form-group>
    <p class="mb-1 font-weight-bold">
      or
    </p>
    <b-form-group
      label="Custom item description"
    >
      <b-form-input
        v-model="form.item_description"
      />
    </b-form-group>
    <hr>
    <b-form-group
      v-if="order === null"
      label="Custom price"
    >
      <b-form-input
        v-model.number="form.price"
        type="number"
        min="0"
      />
    </b-form-group>

    <b-form-group
      label="State"
    >
      <b-form-select
        v-model="form.state"
        :options="states"
        @change="onChangeState"
      />
    </b-form-group>

    <b-form-group
      v-if="order === null"
      label="Payment processor"
    >
      <b-form-input
        v-model="form.processor"
        readonly
      />
    </b-form-group>

    <b-form-group
      label="Due date"
    >
      <flat-pickr
        v-model="form.due_datetime"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
      />
    </b-form-group>

    <b-form-group
      label="Tags"
    >
      <b-badge
        v-for="(item, index) in form.tags"
        :key="index"
        class="m-50"
        :variant="item.tag.color"
      >
        {{ item.tag.name }}
        <b-button
          class="btn-icon rounded-circle"
          size="sm"
          :variant="item.tag.color"
          @click="removeTag(item.tag.id)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-badge>
      <custom-select
        module-name="tagsList"
        label="name"
        not-reduce-by-id
        @input="addTag"
      />

    </b-form-group>
    <b-form-group
      label="Payment methods"
    >
      <b-badge
        v-for="(item, index) in form.payment_methods"
        :key="index"
        class="m-50"
        variant="secondary"
      >
        {{ item.payment_method.name }}
        <b-button
          class="btn-icon rounded-circle"
          size="sm"
          @click="removePaymentMethod(item.payment_method.id)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-badge>
      <custom-select
        module-name="paymentMethodsList"
        label="name"
        not-reduce-by-id
        @input="addPaymentMethod"
      />

    </b-form-group>

  </entity-form>
</template>
<script>
import {
  BFormGroup, BFormSelect, BFormInput, BBadge, BButton,
} from 'bootstrap-vue'
import FlatPickr from 'vue-flatpickr-component'
import { dispatch, get } from 'vuex-pathify'
import EntityForm from '@/components/ui/forms/EntityForm.vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import ProductSelect from '@/components/ui/forms/ProductSelect.vue'
import EntitySelect from '@/components/ui/gqlforms/EntitySelect.vue'

const states = [
  { value: 'paid', text: 'Paid' },
  { value: 'unpaid', text: 'Unpaid' },
  { value: 'pending', text: 'Pending' },
  { value: 'canceled', text: 'Canceled' },
  { value: 'refund', text: 'Refund' },
]

export default {
  components: {
    EntitySelect,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BBadge,
    BButton,
    EntityForm,
    CustomSelect,
    ProductSelect,
    FlatPickr,
  },
  props: {
    order: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        user: this.order ? this.order.user : null,
        product: this.order && this.order.product ? this.order.product.id : null,
        item_description: this.order ? this.order.item_description : null,
        price: this.order ? this.order.price : 0,
        state: this.order ? this.order.state : null,
        due_datetime: this.order ? this.order.due_datetime : null,
        payment_datetime: this.order ? this.order.payment_datetime : null,
        processor: null,
        tags: this.order ? this.order.tags : [],
        payment_methods: this.order ? this.order.payment_methods : [],
      },
      userFilter: {
        name: 'user',
        label: 'User',
        filterable: {
          type: 'entity', queryType: 'users', label: 'email', search: 'email', value: 'id',
        },
      },
      states,
    }
  },
  computed: {
    ...get('auth', ['me']),
  },
  mounted() {
    dispatch('auth/getMe')
  },
  methods: {
    onChangeState() {
      if (this.form.state === 'paid') this.form.payment_datetime = new Date().toISOString()
      else this.form.payment_datetime = null
    },
    beforeSubmit() {
      if (this.form.state === null) return false

      if (this.form.product) {
        delete this.form.item_description
      } else {
        delete this.form.product
      }

      this.form.user = this.form.user?.id || this.form.user
      this.form.original_price = this.form.price
      this.form.payment_datetime = this.form.payment_datetime === null ? null : new Date(this.form.payment_datetime).toISOString()
      this.form.due_datetime = this.form.due_datetime !== null ? new Date(this.form.due_datetime).toISOString() : new Date(Date.now() + 2.592e+9).toISOString()
      this.form.tags = this.form.tags.map(item => ({ ...item, assigner: typeof item.assigner === 'string' || item.assigner === null ? item.assigner : item.assigner.id }))

      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
    addTag(item) {
      if (!this.form.tags.find(tagToUser => tagToUser.tag.id === item.id)) this.form.tags.push({ tag: item, order: this.order.id, assigner: this.me.id })
    },
    removeTag(id) {
      this.form.tags = this.form.tags.filter(item => item.tag.id !== id)
    },
    addPaymentMethod(item) {
      if (!this.form.payment_methods.find(i => i.payment_method.id === item.id)) this.form.payment_methods.push({ payment_method: item, order: this.order.id })
    },
    removePaymentMethod(id) {
      this.form.payment_methods = this.form.payment_methods.filter(item => item.payment_method.id !== id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
