<template>
  <div>
    <b-table
      :items="orders"
      :fields="fields"
      striped
      responsive
      small
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(month)="data">
        {{ data.item.year }}-{{ data.item.month }}
      </template>

      <template #cell(order_count)="data">
        {{ data.item.order_count }}
      </template>

      <template #cell(order_sum)="data">
        {{ formatCurrency(data.item.order_sum, 'USD') }}
      </template>
    </b-table>
    <pagination-row />
  </div>
</template>
<script>
import {
  BTable,
} from 'bootstrap-vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import table from '@/mixins/table'

export default {
  components: {
    BTable,
  },
  mixins: [table],
  props: {
    orders: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'month',
          sortable: true,
        },
        {
          key: 'order_count',
          sortable: true,
        },
        {
          key: 'order_sum',
          label: 'PROFIT SUM',
          sortable: true,
        },
      ],
    }
  },
  watch: {
    orders() {
      this.totalRows = this.orders.length
    },
  },
  created() {
    this.totalRows = this.orders.length
  },
  methods: {
    formatDateTime,
    formatCurrency,
  },
}
</script>
