<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0"
        >
          <h4>Email or name:</h4>
          <b-input-group
            size="sm"
            class="mt-1"
          >
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by email or name"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <data-table
      ref="usersTable"
      :module-name="'registeredUsersList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      @detail="onDetail"
    >
      <template #cell(full_name)="data">
        <b-link :to="{name: 'user-detail', params: {id: data.item.id}}">
          {{ data.item.full_name }}
          <b-avatar
            v-if="data.item.profileImage"
            variant="light-primary"
            size="32px"
            circle
          >
            <b-img
              class="w-100"
              :src="data.item.profileImage"
            />
          </b-avatar>
        </b-link>
      </template>

      <template #cell(email)="data">
        {{ data.item.email }}
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="data.item.extraTags"
          extras-variant="light-success"
        />
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

    </data-table>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BImg,
  BAvatar,
} from 'bootstrap-vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { formatDateTime } from '@/plugins/formaters'
import TagsList from '@/components/page/TagsList.vue'

const fields = [
  {
    key: 'full_name',
    sortable: true,
  },
  {
    key: 'email',
    sortable: true,
  },
  {
    key: 'tags',
    sortable: false,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
  {
    key: 'actions',
    sortable: false,
  },
]

const actions = [
  {
    text: 'Account details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
]

export default {
  components: {
    DataTable,
    BCol,
    BRow,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BLink,
    TagsList,
    BImg,
    BAvatar,
  },
  props: {
    affId: String,
  },
  data() {
    return {
      fields,
      actions,
      advancedFilter: { search: '', aff: null },
      search: '',
    }
  },
  beforeMount() {
    this.advancedFilter.aff = this.affId
  },
  methods: {
    formatDateTime,
    onDetail(item) {
      this.$router.push({ name: 'user-detail', params: { id: item.id } })
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
