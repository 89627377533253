<template>
  <b-overlay :show="loading">
    <b-card>
      <b-card-body>
        <b-form-group>
          <label>Dispute type</label>
          <div class="my-1">
            <b-badge
              v-for="(type, key) in disputeTypes"
              :key="key"
              class="font-medium-1 ml-1 mt-1"
              variant="light-danger"
              @click="disputeType = type"
            >
              {{ type }}
            </b-badge>
          </div>
          <b-form-input v-model="disputeType" />
        </b-form-group>
        <b-form-group>
          <label>Card type</label>
          <div class="my-1">
            <b-badge
              v-for="(type, key) in cardTypes"
              :key="key"
              class="font-medium-1 ml-1 mt-1"
              variant="light-warning"
              @click="cardType = type"
            >
              {{ type }}
            </b-badge>
          </div>
          <b-form-input v-model="cardType" />
        </b-form-group>
        <b-form-group>
          <label>Last 4 digits of card number</label>
          <b-form-input v-model="cardNumber" />
        </b-form-group>
        <b-form-group>
          <label>Email img:</label>
          <file-pond
            ref="filepond-email"
            v-model="emailFile"
            class="filepond-attachments"
            name="ticket-attachments"
            accepted-file-types="image/jpeg, image/jpg, image/png"
            label-idle="<div class='d-flex flex-column'><div class='d-flex flex-column flex-sm-row flex-lg-column flex-xl-row text-header'><span>Select file to upload </span> <span>or drag and drop it here</span></div><span class='text-placeholder font-small-3 d-none d-sm-block d-lg-none d-xl-block'>(.jpg, .png, .webp) Max file size: 5MB</span></div>"
            :allow-multiple="false"
            :credits="''"
            data-max-file-size="5MB"
            :required="true"
          />
        </b-form-group>
        <b-form-group>
          <label>Admin dashboard img:</label>
          <file-pond
            ref="filepond-admin"
            v-model="adminFile"
            class="filepond-attachments"
            name="ticket-attachments"
            accepted-file-types="image/jpeg, image/jpg, image/png"
            label-idle="<div class='d-flex flex-column'><div class='d-flex flex-column flex-sm-row flex-lg-column flex-xl-row text-header'><span>Select file to upload </span> <span>or drag and drop it here</span></div><span class='text-placeholder font-small-3 d-none d-sm-block d-lg-none d-xl-block'>(.jpg, .png, .webp) Max file size: 5MB</span></div>"
            :allow-multiple="false"
            :credits="''"
            data-max-file-size="5MB"
            :required="true"
          />
        </b-form-group>
        <b-form-group>
          <label>MT logins img:</label>
          <file-pond
            ref="filepond-logins"
            v-model="loginFile"
            class="filepond-attachments"
            name="ticket-attachments"
            accepted-file-types="image/jpeg, image/jpg, image/png"
            label-idle="<div class='d-flex flex-column'><div class='d-flex flex-column flex-sm-row flex-lg-column flex-xl-row text-header'><span>Select file to upload </span> <span>or drag and drop it here</span></div><span class='text-placeholder font-small-3 d-none d-sm-block d-lg-none d-xl-block'>(.jpg, .png, .webp) Max file size: 5MB</span></div>"
            :allow-multiple="false"
            :credits="''"
            data-max-file-size="5MB"
            :required="true"
          />
        </b-form-group>
      </b-card-body>
      <b-card-footer>
        <b-button
          class="float-right"
          :disabled="disableSaveBtn"
          @click="onSave"
        >
          Generate
        </b-button>
      </b-card-footer>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardBody, BButton, BCardFooter, BFormGroup, BFormInput, BBadge, BOverlay,
} from 'bootstrap-vue'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OrdersApi from '@/api/order'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    BOverlay,
    FilePond,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      disputeTypes: ['an unauthorized payment', 'an fraud', 'an missing card', 'No Cardholder Authorization', 'Cardholder dispute'],
      cardTypes: ['Visa', 'Mastercard', 'Discover'],
      disputeType: null,
      cardType: null,
      cardNumber: null,
      loginFile: null,
      emailFile: null,
      adminFile: null,
      loading: false,
    }
  },
  computed: {
    disableSaveBtn() {
      return !this.disputeType || !this.cardNumber || !this.cardType || this.emailFile.length === 0 || this.loginFile.length === 0 || this.adminFile.length === 0
    },
  },
  methods: {
    onSave() {
      const files = []
      files.push({ name: 'email-img', file: this.$refs['filepond-email'].getFile() })
      files.push({ name: 'admin-img', file: this.$refs['filepond-admin'].getFile() })
      files.push({ name: 'logins-img', file: this.$refs['filepond-logins'].getFile() })
      this.loading = true
      OrdersApi.generateOrderDisputeReport(this.order.id, this.order.order_number, files, this.disputeType, this.cardNumber, this.cardType)
        .then(() => {
          this.$emit('close')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generating report failed',
              icon: 'SendIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

.filepond-attachments::v-deep {
  .filepond--root {
    margin-bottom: 8px;
    transition: all 150ms ease-in-out;
    border: 2px solid $info;
    border-radius: 7px;

    &:hover {
      background-color: rgba($dark, 12%);
      border: 2px solid $info;
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
  }

  .filepond--panel-top, .filepond--panel-bottom, .filepond--browser {
    background-color: transparent !important;
    opacity: 0 !important;
  }

  .filepond--panel-root {
    background-color: transparent;
  }
}
</style>
