<template>
  <entity-form
    :id="refund ? refund.id : null"
    module-name="refundList"
    :model="form"
    :before-submit="beforeSubmit"
    :automatic-reload="false"
    @submit="onSubmit"
  >
    <b-form-group
      label="Order"
    >
      <b-form-input
        v-model="order.order_number"
        disabled
      />
    </b-form-group>
    <b-form-group
      label="Processor"
    >
      <b-form-input
        v-model="order.processor"
        disabled
      />
    </b-form-group>
    <hr>
    <b-form-group
      label="Price"
    >
      <b-form-input
        v-model.number="form.price"
        type="number"
        min="0"
      />
    </b-form-group>

    <b-form-group
      label="State"
    >
      <b-form-select
        v-model="form.state"
        :options="states"
      />
    </b-form-group>

    <b-form-group
      v-for="(item, key) in form.form"
      :key="key"
      :label="(key.charAt(0).toUpperCase() + key.slice(1)).replace('_', ' ')"
    >
      <b-form-input
        v-model="form.form[key]"
      />
    </b-form-group>

    <b-form-group>
      <label>Comment</label>
      <b-form-textarea
        v-model="form.comment"
        placeholder="Comment"
        rows="8"
      />
    </b-form-group>
  </entity-form>
</template>
<script>
import {
  BFormGroup, BFormSelect, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

const states = [
  { value: 'requested', text: 'Requested' },
  { value: 'completed', text: 'Completed' },
  { value: 'declined', text: 'Declined' },
]

export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BFormInput,
    EntityForm,
  },
  props: {
    refund: {
      required: false,
      type: Object,
    },
  },
  data() {
    let cryptoFormDefault = null
    // eslint-disable-next-line no-prototype-builtins
    if (this.refund.order.order_data && ['Crypto', 'Coinbase', 'Confirmo'].includes(this.refund.order.order_data.processor)) {
      const payment = this.refund.order.payments.find(p => p.success === true)
      cryptoFormDefault = {
        crypto_currency: null,
        address: null,
        payment_id: payment ? payment.payment_id : null,
      }
    }

    return {
      order: this.refund.order.order_data ? this.refund.order.order_data : this.refund.order,
      form: {
        order: this.refund.order.order_data ? this.refund.order.order_data.id : this.refund.order.id,
        price: this.refund.price ? this.refund.price : this.refund.order.order_data.price,
        state: this.refund.state ? this.refund.state : 'requested',
        form: this.refund.form ? this.refund.form : cryptoFormDefault,
        comment: this.refund.comment ? this.refund.comment : null,
      },
      states,
    }
  },
  methods: {
    beforeSubmit() {
      return this.form.state !== null
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
