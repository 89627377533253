<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="4"
          class="mb-2"
        >
          <h3>Order number & user</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by order number or user"
                @keyup.enter="advancedFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="advancedFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>Tags:</h3>
          <tag-tree-select v-model="advancedFilter.tag" />

          <h3
            v-if="useUserTags"
            class="mt-1"
          >
            Users tags:
          </h3>
          <tag-tree-select
            v-if="useUserTags"
            v-model="advancedFilter.userTags"
          />
        </b-col>
        <b-col
          lg="3"
          class="mb-2"
        >
          <h3>States:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="advancedFilter.state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(state, index) in ORDER_STATES"
              :key="index"
              :variant="`light-${state.variant}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="state.name"
                class="vh-center"
              >
                <span :class="`text-${state.variant}`">{{ state.title }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>

          <h3 class="mt-1">
            Payment processors:
          </h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="advancedFilter.processor"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(state, key, index) in processors"
              :key="index"
              :variant="`light-${state}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${state}`">{{ key }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
        <b-col
          lg="1"
          class="mb-2"
        >
          <h3>Others</h3>
          <b-form-checkbox
            v-model="advancedFilter.viewZeroCostOrders"
            class="vh-center my-1"
          >
            <span>View orders with zero price</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <b-button-toolbar>
            <b-button-group>
              <b-button
                variant="primary"
                class="ml-auto"
                @click="onCreate"
              >
                New order
              </b-button>
              <b-button
                v-b-modal.orderTagsModal
                variant="secondary"
              >
                Assign tags to orders
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      ref="orderTable"
      :module-name="'ordersList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      deletable
      @detail="onDetail"
      @edit="onEdit"
      @report="onReport"
      @reportDispute="onDisputeReport"
      @refund="onRefund"
    >
      <template #cell(order_number)="data">
        <b-link :to="{name: 'order-detail', params: {id: data.value}}">
          {{ data.value }}
        </b-link>
      </template>

      <template #cell(tags)="data">
        <tags-list :tags="data.item.tags" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.price, 'USD') }}
      </template>

      <template #cell(payment_datetime)="data">
        {{ formatDateTime(data.item.payment_datetime) }} {{ data.item.processor !== 'null' ? `(${data.item.processor})` : '' }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>
    </data-table>
    <b-modal
      id="orderModal"
      :title="title"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <order-form
        :order="orderEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
    <b-modal
      id="orderTagsModal"
      title="Assign tags to orders"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <multiple-tag-form
        items-name="Order numbers"
        :is-busy="tagsToOrdersLoading"
        show-assign-to-users-btn
        show-purchase-disabled-option
        @submit="onSubmitMultipleTags"
      />
    </b-modal>
    <b-modal
      id="orderReportModal"
      title="Report generator"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <report-form
        :order="orderReport"
        @close="onCloseModal"
      />
    </b-modal>
    <b-modal
      id="refundModal"
      title="Create refund"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <refund-form
        :refund="refundEdit"
        @submit="onCloseRefundModal()"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge, BFormCheckboxGroup, BFormCheckbox,
  BCol, BRow, BCard, BFormGroup, BInputGroup, BFormInput,
  BInputGroupAppend, BButton, BLink, BModal, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import DataTable from '@/components/ui/tables/DataTable.vue'
import OrderForm from '@/components/forms/order/OrderForm.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { ORDER_STATES } from '@/enums/orders'
import OrdersApi from '@/api/order'
import TagsList from '@/components/page/TagsList.vue'
import MultipleTagForm from '@/components/forms/tag/MutipleTagForm.vue'
import TagTreeSelect from '@/components/ui/tables/TagTreeSelect.vue'
import ReportForm from '@/views/order/components/ReportForm.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import RefundForm from '@/components/forms/order-refund/RefundForm.vue'

const fields = [
  {
    key: 'order_number',
    label: 'Order number',
    sortable: false,
  },
  {
    key: 'tags',
    sortable: false,
  },
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'price',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
  {
    key: 'payment_datetime',
    label: 'Payed at',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'actions',
    sortable: false,
  },
]

const actions = [
  {
    text: 'Order details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Generate report',
    icon: 'PaperclipIcon',
    emit: 'report',
  },
  {
    text: 'Generate dispute report',
    icon: 'PaperclipIcon',
    emit: 'reportDispute',
  },
  {
    text: 'Create refund',
    icon: 'EditIcon',
    emit: 'refund',
  },
]

export default {
  components: {
    ReportForm,
    TagTreeSelect,
    DataTable,
    OrderForm,
    TagsList,
    MultipleTagForm,
    RiskScoreBadge,
    RefundForm,
    BButtonGroup,
    BButtonToolbar,
    BBadge,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCol,
    BRow,
    BCard,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BLink,
    BModal,
  },
  props: {
    defaultFilter: {
      type: Object,
      default: null,
    },
    useUserTags: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields,
      stateVariants: {
        unpaid: 'danger',
        paid: 'success',
        pending: 'warning',
        canceled: 'primary',
        refund: 'info',
      },
      processors: {
        Checkout: 'primary',
        Coinbase: 'secondary',
        GiveAway: 'info',
        'Payment Card': 'danger',
        PayPal: 'dark',
        Stripe: 'warning',
      },
      orderEdit: null,
      orderReport: null,
      advancedFilter: null,
      search: '',
      ORDER_STATES,
      actions,
      title: '',
      tagsToOrdersLoading: false,
      refundEdit: null,
    }
  },
  computed: {
    ...get('ordersList', ['filters']),
  },
  beforeMount() {
    if (this.defaultFilter) this.advancedFilter = { ...this.defaultFilter }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    onCreate() {
      this.title = 'Order create'
      this.orderEdit = null
      this.$bvModal.show('orderModal')
    },
    onEdit(item) {
      this.title = 'Order edit'
      dispatch('ordersList/get', item.order_number).then(data => {
        this.orderEdit = data.order_data
        this.$bvModal.show('orderModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('orderModal')
    },
    onDetail(item) {
      this.$router.push({ name: 'order-detail', params: { id: item.order_number } })
    },
    onReport(item) {
      OrdersApi.generateOrderReport(item.id)
    },
    onDisputeReport(item) {
      this.orderReport = item
      this.$bvModal.show('orderReportModal')
    },
    onCloseModal() {
      this.orderReport = null
      this.$bvModal.hide('orderReportModal')
    },
    onSubmitMultipleTags(data) {
      this.tagsToAccountsLoading = true
      OrdersApi.postTagsToOrders(data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tags was assigned to orders',
              icon: 'SendIcon',
              text: 'Tags was successfully assigned to orders.',
              variant: 'success',
            },
          })
          this.$bvModal.hide('orderTagsModal')
        })
        .catch(error => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Some tags was not assign to orders',
              text: error.data.detail,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            })
          }
        })
        .finally(() => {
          this.$refs.orderTable.reloadAll()
          this.tagsToOrdersLoading = false
        })
    },
    onRefund(item) {
      if (item.state !== 'paid') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Only paid orders can be refunded.',
            icon: 'SendIcon',
            variant: 'danger',
          },
        })
      }

      dispatch('ordersList/get', item.order_number).then(data => {
        this.refundEdit = { order: data }
        this.$bvModal.show('refundModal')
      })
    },
    onCloseRefundModal() {
      this.refundEdit = null
      this.$bvModal.hide('refundModal')
      this.$refs.orderTable.reloadAll()
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}

</script>
